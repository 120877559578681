import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import * as firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {
  isLogin = false;
  public userData: any;
  public user: firebase.User;
  public showLoader: boolean = false;
  private currentUserSubject: BehaviorSubject<User>
  public currentUser: Observable<User>

  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private http: HttpClient,
    private cookieService: CookieService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('authUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        cookieService.set('user', JSON.stringify(this.userData));
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', JSON.stringify({ "name": "pooja" }));
        JSON.parse(localStorage.getItem('user'));
      }
    });

    // Method to check user activity, if user is inactive for some period of time then automatic logout
    if (this.SignIn) {
      this.isLogin = true;
      this.lastAction(Date.now());
      if (localStorage.length > 0) {
        this.check();
      }
      this.initListener();
      this.initInterval();
    }
  }

  getLastAction(): string {
    return localStorage.getItem('lastAction');
  }
  lastAction(arg0: any) {
    localStorage.setItem('lastAction', JSON.stringify(arg0));
  }

  check() {
    const now = Date.now();
    const timeLeft = parseInt(this.getLastAction()) + (30 * 60 * 1000);
    const diff = timeLeft - now;
    const isTimeout = diff < 0;
    this.ngZone.run(() => {
      if (isTimeout && this.isLogin) {

        localStorage.clear();
        setTimeout(() => {
          this.toster?.error("Your Session Expired due to longer Inactivity, Login Again To Continue", "Error");
          this.toster.show.length.toFixed(5)
        }, 100);
        this.logout();
      }
    });
  }
  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    })
  }
  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  reset(): any {
    this.lastAction(Date.now());
  }

  ngOnInit(): void { }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // sign in function
  SignIn(email: string, password: string) {
    let obj: any = {};
    obj.email = email;
    obj.password = password;
    obj.isLoginForCustomer = false;
    // return this.afAuth.auth.signInWithEmailAndPassword(email, password)
    //   .then((result) => {
    //     if (result.user.emailVerified !== true) {
    //       this.SetUserData(result.user);
    //       this.SendVerificationMail();
    //       this.showLoader = true;
    //     } 
    return this.http.post<any>(environment.SERVER_URL + 'login', obj).pipe(map(res => {
      if (res && res.data?.token) {
        if (res.data.isCustomer === false) {
          let token = res.data?.tokenPrefix + res.data?.token;
          localStorage.setItem('authToken', JSON.stringify(token));
          localStorage.setItem('authUser', JSON.stringify(res.data));
          this.currentUserSubject.next(res.data);
        } else {
          this.toster?.error("Invalid username or password", "Error");
          this.router.navigate(['/auth/login']);
        }
      }
      return res;
    }))
    // else {
    //       this.showLoader = false;
    //       this.ngZone.run(() => {
    //         this.router.navigate(['/auth/login']);
    //       });
    //     }
    //   }).catch((error) => {
    //     //commment added by kiran for tmp
    //     // this.toster.error('You have enter Wrong Email or Password.');
    //     this.router.navigate(['/dashboard/default']); //added

    //   })
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.router.navigate(['/auth/login']);
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  logoutUser() {
    localStorage.clear();
  }
  // main verification function
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['/dashboard/default']);
      })
  }

  // Sign in with Facebook
  signInFacebok() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }

  // Sign in with Twitter
  signInTwitter() {
    return this.AuthLogin(new auth.TwitterAuthProvider());
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  AuthUser() {
    return localStorage.getItem("authUser")
  }
  AuthToken() {
    return localStorage.getItem("authToken")
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }

  // Authentication for Login
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['/dashboard/default']);
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error);
      });
  }

  // Set user
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
      emailVerified: user.emailVerified
    };
    userRef.delete().then(function () { })
      .catch(function (error) { });
    return userRef.set(userData, {
      merge: true
    });
  }

  // Sign out
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    return this.afAuth.auth.signOut().then(() => {
      this.showLoader = false;
      localStorage.clear();
      this.cookieService.deleteAll('user', '/auth/login');
      this.router.navigate(['/auth/login']);
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null && user.emailVerified != false) ? true : false;
  }



}