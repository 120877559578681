<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/headerLogo2.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Sign in to account</h4>
              <p>Enter your email & password to login</p>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" placeholder="Enter email address"
                  formControlName="email">
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                  class="text text-danger mt-1">
                  Email is required
                </div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                  class="text text-danger mt-1">
                  Invalid Email
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required=""
                  placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">
                  <div *ngIf="f.password?.errors?.required">
                    <span class="text text-danger mt-1">Password is required</span>
                  </div>
                </div>
              </div>
              <div class="form-group mb-0 text-md-right" (click)="forgotPass(forgot)">
                <div class="checkbox p-0">
                  <a [routerLink]="'/authentication/forgot-password'">Forgot password?</a>
                </div>
              </div>
              <div class="mt-2">
                <button class="btn btn-primary btn-block" [class.loader--text]="authService.showLoader"
                   (click)="login()"
                  type="sumbit"><span>Login</span>
                </button>
              </div>
              <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn btn-light" (click)="loginFacebook()" target="_blank">
                      <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook
                  </a>
                  <a class="btn btn-light" (click)="loginTwitter()" target="_blank">
                    <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                  </a>
                  <a class="btn btn-light" (click)="loginGoogle()" target="_blank">
                      <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google +
                  </a>
                </div>
              </div> -->
              <!-- <p class="mt-4 mb-0">Don't have account?
                <a [routerLink]="'/authentication/register/simple'" class="ml-2">Create Account</a>
              </p> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #forgot let-modal>
  <div class="modal-body">
      <div class="container-fluid p-0">
          <div class="row">
              <div class="col-12">
                  <div class="modal-bg addtocart">
                      <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                          aria-hidden="true">×</button>
                      <br>
                      <app-forget-password (onSave)="onSaveFun($event,verifyMail)"></app-forget-password>
                  </div>
              </div>
          </div>
      </div>
  </div>
</ng-template>
<ng-template #verifyMail let-modal>
  <div class="modal-body">
      <div class="container-fluid p-0">
          <div class="row">
              <div class="col-12">
                  <div class="modal-bg addtocart">
                      <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                          aria-hidden="true">×</button>
                      <br>
                      <app-verify-email [email]="tempStoreEmail" (onVerify)="onVerifyFun(newPassword)">
                      </app-verify-email>
                  </div>
              </div>
          </div>
      </div>
  </div>
</ng-template>
<ng-template #newPassword let-modal>
  <div class="modal-body">
      <div class="container-fluid p-0">
          <div class="row">
              <div class="col-12">
                  <div class="modal-bg addtocart">
                      <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                          aria-hidden="true">×</button>
                      <br>
                      <app-new-password [email]="tempStoreEmail" (onNewPwd)="onNewPwdFun()"></app-new-password>
                  </div>
              </div>
          </div>
      </div>
  </div>
</ng-template>