import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { companyDB } from '../../shared/data/tables/company';
import { Pagination } from 'src/app/core/models/pagination-data';
import { GridSetting } from './defualtGridSettings';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { Filters } from 'src/app/core/models/pagination-filters';

@Component({
  selector: 'app-table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.scss'],
})
export class TableGridComponent implements OnInit {

  @Input() title: string;
  @Input() settings: any
  @Input() dataSource: any
  @Output() onFilterChange = new EventEmitter<any>();
  @Output() addOnClick = new EventEmitter<any>()
  pagination = new Pagination();
  ObjetKyes = Object.keys;
  paginationMenuList: any[] = [];
  paginationMenuData = localStorage.getItem('paginationMenuList');
  rows = [];
  dataSourceList: any[] = [];
  dataKey: any[] = [];
  page = 1;
  gridSettings: any = GridSetting;

  columns = [{ name: 'firstName', test: 'sd' }, { name: 'lastName', test: 'sd' }, { name: 'middelName', test: 'sd' }];

  ColumnMode = ColumnMode;
  SortType = SortType;

  constructor(config: NgbPaginationConfig) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }
  ngOnInit(): void {
  }
  ngOnChanges() {
    if (!this.dataSource && this.dataSource?.length === 0) {
      return;
    }
    this.setGridSettings();
  }
  setGridSettings() {
    this.settings.columns.forEach(key => {
      this.dataSourceList = this.dataSource.map(data => {
        if (!data.hasOwnProperty(key.colKey)) {
          data[key.colKey] = null;
          return data;
        }
      })
    })
    this.dataKey = this.settings.columns.map((col) => {
      return { column: col.colKey, columnValue: col.customControl === undefined ? null : col.customControl };
    })
    this.setOptionValue();
  }
  setOptionValue() {
    Object.entries(this.settings).forEach(obj => {
      if (this.gridSettings.hasOwnProperty(obj[0])) {
        this.gridSettings[obj[0]] = obj[1];
      }
    })
  }
  searchKeyword =null;
  onFilter(event) {
    // dinesh code
    let filters = new Filters();
    filters.filterColumn = 'any';
    filters.filterValue = this.searchKeyword;
    let serverObj = new Array<Filters>(filters);
    this.pagination.filters = filters && filters.filterValue ? JSON.parse(JSON.stringify(serverObj)) : null;
    this.pagination.page = 1;
    this.onFilterChange.emit(this.pagination);
  }
  onSort(key) {
    this.pagination.sortOrder = this.pagination && this.pagination.sortOrder === 0 ? 1 : 0;
    this.pagination.sortBy = key;
    this.onFilterChange.emit(this.pagination);
  }
  openForm() {
    this.addOnClick.emit();
  }
  onPageChange(page) {
    this.pagination.page = page
    this.onFilterChange.emit(this.pagination);
  }
  onPageSizeChange(event) {
    if (event) {
      let pageSize = event.target.value;
      this.pagination.pageSize = pageSize;
      this.onFilterChange.emit(this.pagination);
    }
  }
}
