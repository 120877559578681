import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

import { CommonService } from 'src/app/shared/services/common.service';
export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @Output() onPwdChange = new EventEmitter<any>()

  
    changePwdForm = new FormGroup({
    oldPassword: new FormControl(null, [Validators.required]),
    newPassword: new FormControl(null, [Validators.required]),
  })
  public changePassword: FormGroup;
  private currentUserSubject: BehaviorSubject<User>
  constructor(private servive:CommonService, 
    public router: Router, 
    private toaster:ToastrService) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('authUser')));
     }
  public showNew: boolean = true;
  public show: boolean = true;
  

  get f() {
    return this.changePwdForm.controls;
  }

  ngOnInit() {
  }
  submitForm(oldPwd, newPwd) {
    if (!this.changePwdForm.valid) {
      Object.keys(this.changePwdForm.controls).forEach(key => {
        const control = this.changePwdForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else if (this.oldPwdCheck == true) {
      let formdata = new FormData();
      formdata.append('oldPassword', oldPwd);
      formdata.append('newPassword', newPwd);
      this.servive.changePassword(formdata).subscribe(res => {
        console.log("res", res);
        if (res.success == true) {
          this.toaster.success("Password is changed successfully", 'Success');
          this.onPwdChange.emit();
          localStorage.clear();
          this.router.navigate(['/auth/login']);


        } else {
          this.toaster.error("Password not changed, please try again", 'Error');
        }
      })
    }
    console.log("oldPwd", oldPwd);
    console.log("newPwd", newPwd);
  }

  checkOldPassword(oldPwd) {
    
    if(oldPwd=='')
    {
    console.log("called");

      return
    }
    let formdata = new FormData();
    formdata.append('oldPassword', oldPwd);
    this.servive.checkOldPassword(formdata).subscribe(res => {
      console.log("res", res);
      if (res.success == true) {
        this.oldPwdCheck = true;
      } else
        this.oldPwdCheck = false;
    })
  }
  oldPwdCheck: boolean;
  setFlag() {
    // this.entity.mobileNo?'':this.checkMobileFlag=true
    this.oldPwdCheck = true;
  }
  showPassword() {
    this.show = !this.show;
  }
 
  showPasswordNew() {
    this.showNew = !this.showNew;
  }
}
