<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <h3>Reset password</h3>
            <form [formGroup]="resetForm">
                <div class="row">
                    <div class="col-md-12">
                        <label for="">New Password</label>
                        <input  formControlName="newPwd" #newPwd class="form-control"
                            placeholder="Enter New Password" required="" [type]="Toggledata ? 'password' : 'text'">
                            <div type="button" (click)="PasswordIconLogle()" name="nav-submit-button"
                            class="show-hide">
                            <i
                                [ngClass]="Toggledata == true ? 'fa toggle-password fa-eye' : 'fa toggle-password fa-eye-slash'"></i>
                            </div>
                        <div *ngIf="resetForm.controls.newPwd.touched && resetForm.controls.newPwd.errors?.required"
                            class="text text-danger">
                            New Password is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mt-2">
                        <label for="">Confirm Password</label>
                        <input formControlName="confirmNewPwd" #confirmNewPwd class="form-control"
                            placeholder="Enter Confirm Password" required=""
                            [type]="confirmToggleData ?  'password' : 'text'">
                            <div type="button" (click)="PasswordIconLogleConfirm()" name="nav-submit-button"
                            class="show-hide">
                            <i
                                [ngClass]="confirmToggleData == true ? 'fa toggle-password fa-eye' : 'fa toggle-password fa-eye-slash'"></i>
                            </div>
                            <div *ngIf="resetForm.controls.confirmNewPwd.touched && resetForm.controls.confirmNewPwd.errors?.required"
                            class="text text-danger">
                            Confirm Password is required.
                        </div>
                        <!-- <div *ngIf="resetForm.controls.confirmNewPwd.touched && resetForm.controls.confirmNewPwd.errors?.required"
                            class="text text-danger">
                            Confirm Password is required.
                        </div> -->
                    </div>
                </div>
                <span *ngIf="isSamePwd == false" class="text-danger">New password and confirm password must be
                    same.</span>
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-primary btn-block m-t-10"
                            (click)="submitForm(newPwd.value,confirmNewPwd.value)">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>