import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { tap } from 'rxjs/operators';
import {
    HttpResponse,
    HttpErrorResponse
  } from '@angular/common/http';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(private toast: ToastrService,private spinService:NgxSpinnerService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.spinService.show();
        return this.handler(next, request);
      }
    
      handler(next, request) {
        return next.handle(request)
          .pipe(
            tap(
              (event) => {
                if (event instanceof HttpResponse) {
                  this.spinService.hide();
                }
              },
              (error: HttpErrorResponse) => {
                this.spinService.hide();
                throw error;
              }
            ),
          );
      }
}
