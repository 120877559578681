import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;

  constructor(public authService: AuthService, private fb: FormBuilder,
    private router: Router, private toastr: ToastrService,private commonService:CommonService, private modelService:NgbModal) {
    this.loginForm = this.fb.group({
      // email: ['test@gmail.com', [Validators.required, Validators.email]],
      // password: ['test123', Validators.required]
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  // Login With Google
  loginGoogle() {
    this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    this.authService.signInFacebok();
  }

  // Simple Login
  login() {
    
    // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
    if (!this.loginForm.valid) {
      Object.keys(this.loginForm.controls).forEach(key => {
        const control = this.loginForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    }
    else {
      this.loginUser()
    }
  }
  loginUser() {
    console.log("first step");
    this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']).pipe(first()).subscribe(
      res => {
        if (res) {
          if (res.success == false) {
            this.toastr?.error("Invalid Email or Password", "Error"); 
          }
          else {
            let obj = { "email": `${environment.shipEmail}`,"password": `${environment.shipPwd}`}
            this.commonService.shipRocketLogin(obj).subscribe(res=>{
              console.log("resresres",);
              localStorage.setItem('shipToken',res['token']);
            })
            if (res.data?.isCustomer === false) {
              this.router.navigate(['dashboard/default']);
            }
          }
        }
      },
      error => {
        console.log("something")
      });
  }
  get f() {
    return this.loginForm.controls;
  }
  forgotPass(template){
    this.modelService.open(template,{size:'ms'})
  }
  tempStoreEmail=null;
  onSaveFun(e,tmpReff){
    console.log("successfully call",e);
    this.tempStoreEmail = e;
    this.modelService.dismissAll();
    this.modelService.open(tmpReff,{size:'ms'});
  }
  onVerifyFun(newPasswordTmp){
    console.log("onVerifyFun");
    this.modelService.dismissAll();
    this.modelService.open(newPasswordTmp,{size:'ms'});
  }
  onNewPwdFun(){
    console.log("new pwd fun call");
    this.modelService.dismissAll();
  }
}
