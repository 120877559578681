<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <!-- <h4>Change Password</h4> -->
            <form [formGroup]="changePwdForm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="postion-relative">
                            <label for="">Old Password</label>
                            <input [type]="show ? 'password' : 'oldPassword'"
                                formControlName="oldPassword" #oldPassword class="form-control"
                                placeholder="Enter Old Password"
                                required="" (focusout)="checkOldPassword(oldPassword.value)" (keyup)="setFlag()" (keydown)="$event.keyCode != 32 ? $event:$event.preventDefault()">
                            <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="Hide"></span> </div>
                            <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="show"></span></div>
                            <div *ngIf="changePwdForm.controls.oldPassword.touched && changePwdForm.controls.oldPassword.errors?.required"
                                class="text text-danger">
                                Old Password is required.
                            </div>
                            <div *ngIf="oldPwdCheck == false" class="text-danger mb-3">Invalid old password.</div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <label for="">New Password</label>
                        <div class="postion-relative">
                            <input
                                [type]="showNew ? 'password' : 'newPassword'" formControlName="newPassword"
                                #newPassword class="form-control" placeholder="Enter New Password" required="" (keydown)="$event.keyCode != 32 ? $event:$event.preventDefault()">
                                <div class="show-hide" (click)="showPasswordNew()" *ngIf="!showNew" ><span class="Hide"></span>
                                </div>
                                <div class="show-hide" (click)="showPasswordNew()" *ngIf="showNew"><span class="show"></span>
                                </div>
                            <div *ngIf="changePwdForm.controls.newPassword.touched && changePwdForm.controls.newPassword.errors?.required"
                                class="text text-danger">
                                New Password is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-12">
                        <button class="btn btn-primary btn-block m-t-10"
                            (click)="submitForm(oldPassword.value,newPassword.value)">Update Password</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>