import { Component, OnInit, Output ,EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  @Output() onSave = new EventEmitter<any>()
  emailForm = new FormGroup({
    email:new FormControl('',[Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
  })
  public show: boolean = false;

  constructor(private servive:CommonService, private toaster:ToastrService) { }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }
  tempStoreEmail=null;
  submitEmail(email)
  {
    if(!this.emailForm.valid){
      Object.keys(this.emailForm.controls).forEach(key=>{
        const control=this.emailForm.get(key);
        if(control instanceof FormControl){
          control.markAsTouched({onlySelf:true});
        }
      })
    }else{
      this.emailForm.controls['email'].reset();
      console.log(email);
      let formdata = new FormData();
      formdata.append('email',email);
      formdata.append('isForCustomer','false');
      this.tempStoreEmail = email;
      this.servive.sendOTP(formdata).subscribe(res=>{
        console.log("resres",res);
        if(res.success == true){
          // this.isSendMail = false;
          this.onSave.emit(email);
        }else{
          this.toaster.error("Email Address Is Not Exist",'Error');
        }
      })
    }
  }
}
