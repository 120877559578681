<div class="container">
    <div class="row">
        <form action="" [formGroup]="otpForm">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <!-- <div class="">Verify OTP <span class="text-danger">*</span></div>
                        <input class="form-control" type="text"
                            placeholder="Enter OTP" formControlName="otp" #otp autocomplete="off">
                        <div *ngIf="emailForm.controls.email.touched && emailForm.controls.email.errors?.required"
                            class="text text-danger">
                            E-Mail is required.
                        </div>
                        <div *ngIf="emailForm.controls.email.touched && emailForm.controls.email.errors?.pattern"
                            class="text text-danger">
                            Enter Valid E-Mail.
                        </div> -->
                        <div class="container height-100 d-flex justify-content-center align-items-center">
                            <div class="position-relative">
                                <div class="card p-2 text-center">
                                    <h6>Please enter the one time password <br> to verify your account</h6>
                                    <div> <span>A code has been sent to</span> <small>&nbsp; {{email}}</small> </div>
                                    <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
                                        <input #otp1 formControlName="otp1" class="m-2 text-center form-control rounded"
                                            type="text" id="first" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp2 formControlName="otp2" class="m-2 text-center form-control rounded"
                                            type="text" id="second" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp3 formControlName="otp3" class="m-2 text-center form-control rounded"
                                            type="text" id="third" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp4 formControlName="otp4" class="m-2 text-center form-control rounded"
                                            type="text" id="fourth" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp5 formControlName="otp5" class="m-2 text-center form-control rounded"
                                            type="text" id="fifth" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp6 formControlName="otp6" class="m-2 text-center form-control rounded"
                                            type="text" id="sixth" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                    </div>
                                    <div class="mt-4"> <button
                                            (click)="verifyOTP(otp1.value,otp2.value,otp3.value,otp4.value,otp5.value,otp6.value)"
                                            class="btn btn-danger px-4 validate">Validate</button> </div>
                                    <div class="col-md-12 mt-2 footer-copyright text-center">
                                        <span class="footer-copyright">
                                            <p class="footer-copyright" *ngIf="!resendOtp">Wait for {{ display }} seconds to resend OTP</p>
                                            <!-- <p *ngIf="displayTimer">{{ display }}</p> -->
                                            <!-- <button class="btn btn-info" *ngIf="resendOtp" (click)="start(1)">Resend OTP</button> -->
                                            <a href="javscript:void(0)" *ngIf="resendOtp" (click)="resendOTP()"><p class="mb-0">Resend OTP</p></a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="">

                </div>
            </div>
        </form>
    </div>
</div>

