import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/services/firebase/auth.service';
@Injectable({
  providedIn: 'root'
})
export class JwtIntercepterService implements HttpInterceptor {
  constructor(private authService:AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.authService.AuthToken()){
      if(!req.headers.has('Authorization')){
        req = req.clone({
          setHeaders: { Authorization: JSON.parse(this.authService.AuthToken()) }
        });
      }
    }
    return next.handle(req);
  }
}
