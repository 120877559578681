import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { id } from '@swimlane/ngx-datatable';
import { ResponseData } from 'src/app/core/models/base-save-update-response-modal';
import { FilterTypeValue } from 'src/app/pages/filter-type-value/model/FilterTypeValue';
import { Category } from 'src/app/pages/category/model/Category';
import { DiscountType } from '../../pages/model/DiscountType'
import { Publisher } from 'src/app/pages/model/Publisher';
import { Orders } from 'src/app/pages/orders/model/Orders';
@Injectable({
  providedIn: 'root'
})
export class CommonService {


  public getStateByCountry(id): Observable<ResponseData<any>> {

    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'state/getStateByCountry/' + id
    );
  }

  constructor(private http: HttpClient) { }

  public getStateList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'state/'
    );
  }

  public getCountryList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'country/'
    );
  }

  public getAuthorList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'author/'
    );
  }
  public getAllProduct(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'product/'
    );
  }
  public getPublisherList(): Observable<ResponseData<Publisher>> {
    return this.http.get<ResponseData<Publisher>>(
      environment.SERVER_URL + 'publisher/'
    );
  }
  public getStandardList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'standard/'
    );
  }
  public getFilterTypeValues(): Observable<ResponseData<FilterTypeValue>> {
    return this.http.get<ResponseData<FilterTypeValue>>(
      environment.SERVER_URL + 'filterTypeValue'
    );
  }

  public getFilterType(): Observable<ResponseData<FilterTypeValue>> {
    return this.http.get<ResponseData<FilterTypeValue>>(
      environment.SERVER_URL + 'filterType'
    );
  }
  public getCategories(): Observable<ResponseData<Category>> {
    return this.http.get<ResponseData<Category>>(
      environment.SERVER_URL + 'category/getLeastCategories'
    );
  }
  public getCategoryById(id): Observable<ResponseData<Category>> {
    return this.http.get<ResponseData<Category>>(
      environment.SERVER_URL + 'category/' + id
    );
  }
  public getDiscountTypes(): Observable<ResponseData<DiscountType>> {
    return this.http.get<ResponseData<DiscountType>>(
      environment.SERVER_URL + 'discountType'
    );
  }
  public getFilters(categoryId, productId): Observable<ResponseData<DiscountType>> {
    return this.http.get<ResponseData<DiscountType>>(
      environment.SERVER_URL + 'category/' + categoryId + "/" + productId
    );
  }
  public getOrderById(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/' + id
    );
  }

  public getAwbList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/getAWBList'
    );
  }
  public getFiltersCategory(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'category/' + id)
  }
  public getTrackedByShipmentId(id): Observable<ResponseData<any>> {
    let token = localStorage.getItem('shipToken');
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', 'Bearer' + JSON.parse(JSON.stringify(token)));
    return this.http.get<ResponseData<any>>(
      environment.SHIP_ROCKET_ENV + 'external/courier/track/shipment/' + id, { 'headers': headers }
    );
  }

  public updateOrderData(data): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(
      environment.SERVER_URL + 'order/update', data
    );
  }

  public shipRocketLogin(data): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(
      environment.SHIP_ROCKET_ENV + 'external/auth/login', data
    );
  }
  public sendOTP(email): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'customer/sendmail', email
    );
  }
  public verifyOTP(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'customer/verifyOtp', data
    );
  }
  public resetPassword(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'customer/forgotPassword', data
    );
  }
  public changePassword(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'customer/changePassword', data
    );
  }
  public checkOldPassword(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'customer/checkOldPassword', data
    );
  }

  public getChildList(id): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(
      environment.SERVER_URL + 'category/checkForChild', id
    );
  }

  public downloadPartnerImage(id): Observable<Blob> {
    return this.http.get(environment.SERVER_URL + "fileDownload/getOurPartnersDtoImage/" + id, { responseType: 'blob' });
  }

  // public getCustomerLabelDownload(ids): Observable<ResponseData<any>> {
  //   let token = localStorage.getItem('shipToken');
  //   const headers = new HttpHeaders()
  //     .set('content-type', 'application/json')
  //     .set('Authorization', 'Bearer' + JSON.parse(JSON.stringify(token)));
  //   return this.http.post<ResponseData<any>>(
  //     environment.SHIP_ROCKET_ENV + 'external/courier/generate/label', ids, { 'headers': headers }
  //   );
  // }

  public getCustomerInvoiceDownload(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/invoiceGenerate/' + id)
  }
  public cancelOrder(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/cancelOrder/' + id
    );
  }

  public placeRefundRequest(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/placeRefundRequest/' + id
    );
  }

  public placeShipOrderRequest(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/placeShipOrderRequest/' + id
    );
  }

  public getCount() {
    return this.http.get<any>(environment.SERVER_URL + "dashboard/count");
  }

  public getCustomerLabelDownload(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'order/labelGenerate/' + id)
  }
}
