import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'category',
    loadChildren: () => import('../../pages/category/category.module').then(m => m.CategoryModule)
  },
  {
    path: 'product',
    loadChildren: () => import('../../pages/product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'filters',
    loadChildren: () => import('../../pages/filter-type/filter-type.module').then(m => m.FilterTypeModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('../../pages/orders/orders.module').then(m => m.OrdersModule)
  },
  /*
  {
    path: 'payments',
    loadChildren: () => import('../../pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  */
  {
    path: 'customers',
    loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'razerpay',
    loadChildren: () => import('../../pages/razerpay/razerpay.module').then(m => m.RazerpayModule)
  },
  {
    path: 'authors',
    loadChildren: () => import('../../pages/authors/authors.module').then(m => m.AuthorsModule)
  },
  {
    path: 'publisher',
    loadChildren: () => import('../../pages/publisher/publisher.module').then(m => m.PublisherModule)
  },
  {
    path: 'slider',
    loadChildren: () => import('../../pages/slider/slider.module').then(m => m.SliderModule)
  },
  {
    path: 'ourPartners',
    loadChildren: () => import('../../pages/our-partners/our-partners.module').then(m => m.OurPartnersModule)
  },
  {
    path: 'ourTeam',
    loadChildren: () => import('../../pages/our-team/our-team.module').then(m => m.OurTeamModule)
  },
  {
    path: 'inquiry',
    loadChildren: () => import('../../pages/enquiry/enquiry.module').then(m => m.EnquiryModule)
  },
  {
    path: 'review',
    loadChildren: () => import('../../pages/review/review.module').then(m => m.ReviewModule)
  },
  {
    path: 'coupon',
    loadChildren: () => import('../../pages/coupan/coupan.module').then(m=> m.CoupanModule)
  },
  {
    path: 'ourServices',
    loadChildren: () => import('../../pages/our-services/our-services.module').then(m=> m.OurServicesModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('../../pages/blog/blog.module').then(m=> m.BlogModule)
  },
];
