// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "Your Api Key",
    authDomain: "Your Auth Domain",
    databaseURL: "Your Database Url",
    projectId: "Your Project Id",
    storageBucket: "Your StorageBucket url",
    messagingSenderId: "Your Sender Id"
  },
  shipEmail:'techiebrothers2@gmail.com',
  shipPwd:'India$1947',
  SHIP_ROCKET_ENV: 'https://apiv2.shiprocket.in/v1/',
  // SERVER_URL: "http://techie-e-commerce-backend-staging.us-east-1.elasticbeanstalk.com/api/v1/"//bhavesh
  //  SERVER_URL:"http://localhost:8080/api/v1/"//bhavesh
  SERVER_URL:"https://api.diwalipurayouth.com/api/v1/",//diwalipurayouth
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
