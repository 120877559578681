<div class="media profile-media">
  <img class="b-r-10 " height="37" width="37" src="assets/images/dashboard/user_blue.jfif" alt="">
  <div class="media-body "><span>Welcome Admin</span>
    <p class="mb-0 font-roboto  ">{{user.email}}<i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li><a href="javascript:void(0)"><app-feather-icons [icon]="'lock'"></app-feather-icons><span (click)="changePass(changePassword)">Change Password</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span (click)="logout()">Log Out</span></a></li>
</ul>
<!-- <ng-template #changePassword let-modal>
  <div class="modal-body">
      <div class="container-fluid p-0">
          <div class="row">
              <div class="col-12">
                  <div class="modal-bg addtocart">
                    <h5 class="modal-title mt-0">Change Password</h5>
                      <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                          aria-hidden="true">×</button>
                      <br>
                      <app-reset-password></app-reset-password>                                                                             
                  </div>
              </div>
          </div>
      </div>
  </div>
</ng-template> -->
<ng-template #changePassword let-modal>
  <div class="modal-header">
      <h5 class="modal-title mt-0">Change Password</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <app-reset-password (onPwdChange)="close()"></app-reset-password>
  </div>
</ng-template>
