import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/firebase/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  user: any;
  constructor(public authService: AuthService,private modeService:NgbModal) { }

  ngOnInit() {
    this.user = JSON.parse(this.authService.AuthUser());
  }
  logout(){
    console.log("The Logout Works")
    this.authService.logoutUser();
  }
  changePass(template)
  {
   this.modeService.open(template,{size:"ms"}) 
  }
  close(){
    this.modeService.dismissAll();
  }
}
